#mobile-skills-section {
  display:none;
}

html { 
  font-size: 62.5%; } 
body {
overflow-y: scroll;
}


/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 { 
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 2rem; }
h1 { font-size: 4.0rem; line-height: 1.2;  letter-spacing: -.1rem;}
h2 { font-size: 3.6rem; line-height: 1.25; letter-spacing: -.1rem; }
h3 { font-size: 3.0rem; line-height: 1.3;  letter-spacing: -.1rem; }
h4 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
h5 { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
h6 { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0; }

/* Larger than phablet */
@media (min-width: 550px) {
  h1 { font-size: 5.0rem; }
  h2 { font-size: 4.2rem; }
  h3 { font-size: 3.6rem; }
  h4 { font-size: 3.0rem; }
  h5 { font-size: 2.4rem; }
  h6 { font-size: 1.5rem; }
}

p {
  margin-top: 0; }

* {
box-sizing: border-box;
margin: 0;
}

section.skills-section {
    margin: 40px 0 40px;
}



/********************************************************************************************************************************
                                            Hexagon Intro Blocks
********************************************************************************************************************************/
.intro-block {
position: relative;
display: flex;
align-items: center;
height: 100%;
}

.hex-bg-big-text {
position: absolute;
z-index: -1;
height: 100%;
width: 100%;
}

.centerfold-wrap {
margin: 80px;
position: relative;
display: flex;
justify-content: center;
align-items: center;
flex: 1;
}

.hoverblock {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.hover-notify {
position: absolute;
bottom: -80px;
left: 0;
width: 100%;
text-align: center;
font-size: 50px;
}

.code-display {
position: relative;
color: #f6f6f6;
width: 600px;
height: 260px;
}

.code-display::before {
content: "Skills & Education";
position: absolute;
font-family: 'poppins';
top: -70px;
left: 0;
font-size: 5.0rem;
color: #DEE2EC;
}

.code-display h1 {
color: #333;
}

.code-description {
width: 100%;
height: 100%;
color: #DEE2EC;
}

.code-description * {
display: none;
}

.code-description > div {
padding: 0px 40px;
font-size: 26px;
border-left: 1px solid #DEE2EC24;

}

.desc-active {
display: block;
/*height: 100%;*/
position: relative;
}

.desc-active > p {
display: block;
margin: 0;
}

.desc-active > p > span {
display: inline-block;
/* font-weight: bold; */
/* width: 125px; */
}

p.code-title {
position: relative;
font-size: 6rem;
}

.desc-active::after {
position: absolute;
height: 100%;
width: 100%;
top: 0;
left: 0;
content: "";
opacity: .2;
z-index: -1;
}

.backend-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.backend-icon {
    color:#f4455b;
}

.frontend-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.frontend-icon {
    color: #f4455b;
}

.html-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.education-icon {
    color:#f4455b;
}

.css-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.javascript-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.design-icon {
    color: #f4455b;
}

.gui-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.seo-desc.desc-active::after {

background-repeat: no-repeat;
background-size: contain;
background-position: right center;
}

.others-icon {
    color: #f4455b;
}

.database-icon {
    color: #f4455b;
}
















.hex-master-wrap {
position: relative;
/* width: 50%; */
min-width: 665px;
height: 460px;
}

.border-1,
.border-2,
.border-3 {
position: absolute;
width: 100%;
height: 88px;
top: 46px;
left: 0;
border-left: 1px solid;
border-right: 1px solid;
border-radius: 8px;
z-index: 2;
border-color: #34375630;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.border-2 {
transform: rotate(60deg);
}

.border-3 {
transform: rotate(120deg);
}

.border-3::before {
display: none;
position: absolute;
content: "";
bottom: 10%;
left: 42px;
width: 50%;
height: 83%;
background-color: rgba(255, 255, 255, 0);
border-top: 1px solid;
border-bottom: 1px solid;
border-color: #343756;
border-radius: 50%;
visibility: visible;
z-index: -1;
/* transform: rotate(60deg); */
}






.intro-block .grid-1,
.intro-block .grid-2,
.intro-block .grid-3 {
display: flex;
align-items: center;
justify-content: center;
width: 75%;
margin: 0 auto;
margin-bottom: -50px;
}

.intro-block .grid-3 {
margin-bottom: -50px;
}

.intro-block .grid-2,
.intro-block .grid-3 {
margin-top: -50px;
}

.hex-wrap {
position: relative;
width: 150px;
height: 190px;
margin: 0 1em 0 0;
border-top-left-radius: 100%;
border-top-right-radius: 100%;
border-bottom-left-radius: 100%;
border-bottom-right-radius: 100%;
border: 0px solid;
transform: scale(1.1);
}

.hex-wrap .label {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin: auto;
width: 100%;
height: 58px;
color: #f6f6f6;
display: flex;
justify-content: center;
align-items: center;
z-index: 3;
}

.hex-wrap .label svg, .hex-wrap .label img {
height: 50px;
width: 50px;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.hexagon {
width: 300px;
height: 150px;
margin: 15px 0 0 -75px;
overflow: hidden;
visibility: hidden;
-webkit-transform: rotate(120deg);
-moz-transform: rotate(120deg);
-ms-transform: rotate(120deg);
-o-transform: rotate(120deg);
transform: rotate(90deg);
cursor: pointer;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.hex-inner-1 {
overflow: hidden;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
-webkit-transform: rotate(-60deg);
-moz-transform: rotate(-60deg);
-ms-transform: rotate(-60deg);
-o-transform: rotate(-60deg);
transform: rotate(-60deg);
}

.hex-inner-2 {
position: relative;
width: 100%;
height: 100%;
background-repeat: no-repeat;
background-position: 50%;
/* background-image: url(http://placekitten.com/240/240); */
visibility: visible;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
-webkit-transform: rotate(-60deg);
-moz-transform: rotate(-60deg);
-ms-transform: rotate(-60deg);
-o-transform: rotate(-60deg);
transform: rotate(-60deg);
overflow: hidden;
}







.hex-inner-2::before, .hex-inner-2::after {
position: absolute;
background-repeat: no-repeat;
background-position: 50%;
background-size: contain;
top: 0;
left: 0;
height: 110%;
width: 110%;
content: "";
-webkit-transform: rotate(30deg);
transform: rotate(30deg);
}

/**********************************************************************************
                                Hex Hover Area
**********************************************************************************/
.hex-init {
position: absolute;
top: 46px;
left: 0px;
height: 46%;
width: 100%;
z-index: 9;
transform: rotate(-60deg);
cursor: pointer;

}

.hex-init::after {
width: 100%;
height: 100%;
display: block;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
content: "";
transform: rotate(-60deg);

}

.hex-init::before {
width: 100%;
height: 100%;
display: block;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
content: "";
transform: rotate(60deg);

}

/* .border-1::after, .border-2::after, .border-3::after{
border-color: #FF4747;
} */

.drop-down-skills {
  display: none;
}

/* mobile view */
@media (max-width: 414px){
  #skills-section {
    display: none;
  }
  #mobile-skills-section {
    display: block;
    margin: 34px;
  }
  .accordion {
    width: 50%;
    margin: auto auto 80px;
    border-right:.1px solid#f4455b

    
  }
 
  .accordion-button {
    padding: auto;
    color: #DEE2EC;
    font-size: large;
    font-family: 'poppins';
    background-color: #222438;
  }
  .accordion-item {
    border: none;
  }

  .accordion-button {
    text-align: center;
 
  }
  .accordion-header {
    
    text-align: center;
    background-color: #222438;
  }
  .accordion-body {
    font-size: small;
    padding: auto;
    color: #DEE2EC;
    font-family: 'poppins';
    background-color: #222438;
  }
  .accordion-title {
 
    width: 100%;
    margin: auto;
 
  }


  .skills-edu-list {
    text-align: left;
  }
  .mobile-skills-title {
    text-align: center;
    color: #dee2ec;
    font-size: 3rem;
    margin-bottom: 25px;
  }
  .open {
    margin-bottom: 50px;
  }
  .skills-btn{
    color: #dee2ec;
    background-color: #f4455b;
    border-color: none;
    width: 100%;
    margin-bottom: 10px;
  }
}
/* ipad view */
@media only screen and (max-width: 1080px)and (min-width: 414px){
  #skills-section {
    display: none;
  }
  #mobile-skills-section {
    display: block;
    margin: 34px;
  }
  .accordion {
    width: 50%;
    margin: auto auto 80px;
    border-right:.1px solid#f4455b

    
  }
 
  .accordion-button {
    padding: auto;
    color: #DEE2EC;
    font-size: large;
    font-family: 'poppins';
    background-color: #222438;
  }
  .accordion-item {
    border: none;
  }

  .accordion-button {
    text-align: center;
 
  }
  .accordion-header {
    
    text-align: center;
    background-color: #222438;
  }
  .accordion-body {
    font-size: small;
    padding: auto;
    color: #DEE2EC;
    font-family: 'poppins';
    background-color: #222438;
  }
  .accordion-title {
 
    width: 100%;
    margin: auto;
 
  }

  .skills-edu-list {
    text-align: left;
  }
  .mobile-skills-title {
    text-align: center;
    color: #dee2ec;
    font-size: 3rem;
    margin-bottom: 25px;
  }
  .open {
    margin-bottom: 50px;
  }
  .skills-btn{
    color: #dee2ec;
    background-color: #f4455b;
    border-color: none;
    width: 100%;
    margin-bottom: 10px;
  }
}