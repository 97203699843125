body {
    background-color: #1E2133;
    font-family: 'poppins';
}

a:link, a:visited, a:active, a {
    text-decoration: none!important;
    color: inherit;
}
.container-fluid {
    padding: 2% 15%;
}

/* Navbar */
/* .navbar {
    padding: 0 2px 30px 63%;
    
} */

.navbar-brand {
    font-family: 'poppins';
    font-size: 3rem;
    font-weight: normal;
}
.ml-auto, .mx-auto, .navbar-toggler {
    margin-left: auto!important;
}
.navbar-toggler {
    width: 50px;;
    margin-top: 20px;
}

/* .nav-item {
    text-align: right;
} */
.nav-link {
    font-family: "poppins";
    font-size: 2rem;
    font-weight:normal;
    padding-left: 1.5rem!important;
}

/* nav ends */
.hero-row{
    margin-top: 60px;
    padding-left: 64px;
}
.title{

    margin: 110px 0 0 0;
    text-shadow: 0 1.5px 3px #222438;
    font-family: Poppins;
    font-weight: 900;
    font-size: 7rem;
   
    color: #dee2ec;
}

.title-2 {

    text-shadow: 0 1.5px 3px #222438;
    font-family: Poppins;
    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.17px;
    text-align: left;
    color: #dee2ec;
}
.colored-word{
    color: #f4455b;
}
.p-title{
    width: 450px;
    height: 76.5px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #dee2ec;
}
.hero-image {
    width: 35%;
    margin-left: -34px;
    position: absolute;
}
.hero-button{
    margin-right:3%;
    color: #dee2ec;
    width: 200px;
    height: 40px;
}
.download-button {
    background: #67c3d0;
}
.contactme-button {
    background: #f4455b;
}

/* about style */

.about-image {
    width: 200px;
    margin: 5.5px 69px 10px 87px;
}
.about-left-title {
    margin-bottom: 0;
    text-shadow: 0 1.5px 3px #222438;
    font-family: Poppins;
    font-weight: 900;
    font-size: 4rem;
   
    color: #dee2ec;
}

.container-card{
    width: 77%;
    margin: 125px auto 0 auto;
    padding: 80px 77px 17.6px 96px;
    border-radius: 31px;
    background-color: #222438;
}
.about-left-info {
    width: 270px;
    height: 78px;
    margin: 28px 91.5px 29.5px 0;
    font-family: 'poppins';
    font-size: 14px;
    font-weight:100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: left;
    color: #dee2ec;
}
.info-field {
    color: #FF4747;
}
.about-desc {
    
    height: 141px;
    margin: 21px 0 51.2px;
    font-family: 'poppins';
    font-size: 13.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: left;
    color: #dee2ec;
}

/* Portfolio */
.portfolio-title {
    margin: 60px 0 60px 0;
    text-shadow: 0 1.5px 3px #222438;
    font-family: Poppins;
    font-weight: 900;
    font-size: 5rem;
    color: #dee2ec;
    text-align: center;
}

.project-row {
    margin: 20px 0 20px 100px;
}

.project-item {   
    display:inline-block;
    padding-left: 0;
    width: 360px;
    height: 160px;
    margin: 0 30px 30px 0;  
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: solid 0.5px #34375610;
    background-color: #222438;
}
.project-item-title {
    width: 150px;
    height: 25px;
    margin: 25px auto 4px 29px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #f4455b;
}
.project-item-p {
    width: 137px;
    height: 62px;
    margin: 4px 0 44px 29px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
}
.project-image {
    display: inline-block;
    -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);
}

.project-image:hover {
    -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
  filter: grayscale(40%);
}

.project-item-desc {
    display: inline-block;
    vertical-align: top;
}

.contact-me-title {
    margin: 60px 0 0 0;
    text-shadow: 0 1.5px 3px #222438;
    font-family: Poppins;
    font-weight: 900;
    font-size: 5rem;
    color: #dee2ec;
    text-align: center;
}
.contact-me-row {
    width: 77%;
    margin: 50px auto 125px auto;
    padding: 60px 77px 80px 96px;
    border-radius: 31px;
    background-color: #222438;    

}

.contact-me-form {
    width: 60%;
    margin: 0 auto;
}
.contact-me-label {
    font-size: medium;
    color: #dee2ec;
}
 
.send-message-button {
    background: #f4455b!important;
    width: 100%;
    margin-bottom: 10px;
}

#footer {
    padding: 1% 15%;
    text-align: center;
    color:aliceblue;
    font-family: 'poppins';
    font-size: medium;
}

.form-control {
    margin-top: 8px;
    height: 40px;
}

.form-control-message-area {
    height: 80px!important;
}

#success-notice {
    color: white;
}

/* mobile view */
@media (max-width: 414px) and (min-width: 375px){
    .ml-auto, .mx-auto, .navbar-toggler {
        margin-left: 1!important;
    }
    .nav-link {
        background-color: #222438;
        text-align: left;
    }
    .nav-item {
        border: 1px;
        border-style: solid;
        border-bottom-color: #f0f8ff10;
    }
    .hero-row {
        margin-top: 60px;
        text-align: left;
        padding-left: 0;
    }
    .hero-image {
        position: inherit;
        width: 100%;
    }
    .title{
        text-align: left;
        margin-top: 0;
        font-size: 3rem;
        ;
    }
    .title-2{
        font-size: 2rem;
    }

    .p-title {
        width: 252px;
        margin-bottom: 60px;
        text-align: left;
    }
    .hero-button {
        margin: 2px;
        width: 122px;
        font-size: 11px;
    }
    .hero-image {
        margin: 24px auto 24px;
    }
    .about-left-info {
        text-align: left;
    }
    .about-left-title {
        text-align: center;
        font-size: 3rem;
    }
    .info-field {
        font-size: 1.3rem;
    }
    
    p {
        font-size: 1.3rem;
        text-align: left;
    }
    .about-left-info {
        margin-bottom: 35px;
    }
    .about-image {
        width: 150px;
        margin: 0 45px 10px;
    }

    .about-desc {
        font-size: 1.1rem;
    }

    .container-card {
        width: auto;
        margin-top: 30px;
        padding: 7px 18px 173px 14px;
    }
    .portfolio-title {
        font-size: 3rem;
        margin: 30px auto 30px auto;
    }

    .project-row {
        margin: 22px 0 0 0;
    }

    .project-item-title {
        margin-left: 0;
        width: 150px;
    }
    .project-item-p {
        margin-left: 0;
    }
    .contact-me-title {
        font-size: 3rem;
        margin: 30px auto 30px auto;
    }
    .contact-me-row {
        width: auto;
        padding: 30px 0 30px 0;
        border-radius: 15px;
    }
    .contact-me-form {
        width: 100%;
    }
    .send-message-button {
        background: #f4455b;
        width: 100%;
    }
}

@media only screen and (max-width: 1080px) and (min-width: 992px){
    .title {
        margin-top: 40px;
        font-size: 4rem;
    }
    .title-2 {
        font-size: 3rem;
    }
    .p-title {
        width: auto;
        margin: 10px auto;
    }
    .hero-image {
        width: 350px;
    }
    .hero-button {
        margin: 2%;
        width: 120px;
    }
    .about-left-title {
        font-size: 3rem;
    }
    p {
        font-size:13px ;
    }
    .about-image {
        width: 150px;
        margin-top: 32.5px;
    }
    .portfolio-title {
        font-size: 3rem;
    }
    .project-row {
        margin: auto;
    }
    .project-item {
        display: inline;
        margin: 10px auto;
    }
    .contact-me-title {
        font-size: 3rem;
    }
    .contact-me-form {
        width: 100%;
    }
    .send-message-button {
        width: 100%;
    }
}
@media (max-width: 991px) and (min-width: 730px){
    .title {
        margin-top: 40px;
        font-size: 4rem;
    }
    .title-2 {
        font-size: 3rem;
    }
 
    .hero-image {
        position: inherit;
        width: 350px;
        margin: 20px;
    }
    .hero-button {
        width: 120px;
    }
    .container-card {
        margin: 0 auto auto auto;
        padding: 20px 77px 125px;
    }
    .about-left-title {
        text-align: center;
        font-size: 3rem;
    }
    p {
        font-size:13px ;
    }
    .about-image {
        margin-top: 32.5px;
    }
    .portfolio-title {
        font-size: 3rem;
    }
    
    .project-row {
        margin: auto;
    }
    .project-item {
        display: inline;
        margin: 10px auto;
    }
    .contact-me-row {
        width: 100%;
    }
    .contact-me-title {
        font-size: 3rem;
    }
    .contact-me-form {
        width: 100%;
    }
    .send-message-button {
        width: 100%;
    }
}

@media screen and (max-width: 729px) and (min-width: 415px){
    .title{
        font-size: 4rem;
        margin-top: 30px;
    }
    .title-2 {
        font-size: 3rem;
    }
    .p-title {
        width: auto;
    }
    .hero-image {
        position: static;
        margin: 30px auto 0 auto;
        width: 80%;
    }
    .hero-button {
        width: auto;
    }
    .container-card {
        width: 100%;
        margin: 30px auto 0 auto;
        padding-top: 20px;
        padding-bottom: 70px;
    }
    .about-image {
        width: 150px;
    }

    .about-left-title{
        text-align: center;
        font-size: 3rem;
    }
    .portfolio-title {
        font-size: 3rem;
    }
    .contact-me-title {
        font-size: 3rem;
        margin-top: 10px;
    }
    .contact-me-row {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}
    